<template>
	<div class="-fullbg">
		<div class="-text">欢迎使用</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
.-empty { text-align: center; padding: 80px 0; color: #545454;}
.-fullbg{width: 100%;height: 100%;background: url(../../assets/image/welcome.png) no-repeat center;background-size: cover;display: flex; flex-direction: column; justify-content: center; align-items: center;}
.-text{font-size: 70px;color: #2db2e3;margin-top: -150px;}
</style>
